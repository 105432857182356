<template>
  <v-app>
    <template v-if="loggedIn">
      <dashboard-core-app-bar v-model="expandOnHover" />

      <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

      <dashboard-core-view />

      <!--<dashboard-core-settings v-model="expandOnHover" />-->
    </template>
    <template v-else-if="!isProtectedView">
      <dashboard-core-view />
    </template>
    <template v-else>
      <dashboard-core-login></dashboard-core-login>
    </template>
  </v-app>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'

  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
      DashboardCoreLogin: () => import('./components/core/Login'),
    },

    data: () => ({
      expandOnHover: false,
    }),

    computed: {
      ...mapState(['loggedIn']),
      isProtectedView () {
        if (this.$route.path === '/') return true
        return this.$route.fullPath.includes('admin')
      }
    },

    mounted() {
      console.log(this.loggedIn)
    },

    methods: {
      ...mapMutations({
        setLoggedIn: 'SET_LOGGED_IN',
      }),
    },
  }
</script>
